.news-container {
  border-radius: 10px !important;
  height: 300px;
  img {
    object-fit: cover;
    width: 100%;
    height: 90px;
  }
}

.news-paragraph-collapsed:before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(transparent 5vh, #f8f9fa);
}

.news-paragraph-collapsed {
  max-height: 20vh;
  overflow: hidden;
}

.see-more {
  text-align: right;
  flex: 1 1 auto;
  justify-content: flex-end;
  align-items: flex-end;
  padding-right: 10%;
}
