//
// -- Ejemplo --
//

// h1 {
//   color: $blue;
// }

@font-face {
  font-family: 'Averta-Regular';
  src: local('AvertaRegular'), url('./fonts/Averta-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Averta-Bold';
  src: local('AvertaBold'), url('./fonts/Averta-Bold.woff') format('woff');
}

html {
  position: relative;
  min-height: 100%;
}

body {
  height: 10px;
  padding-top: 60px;
  background-color: $primary-bg;
  font-family: Averta-Regular !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Averta-Bold !important;
}

.header {
  width: 100%;
  height: 50%;
  position: absolute;
  background-color: $primary;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 50% 20%;
  border-bottom-right-radius: 50% 20%;
  z-index: -1;
}

.d-inline-block {
  width: 100px;
}

.logo-footer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.logo-footer {
  width: 100px !important;
}

.nav-container {
  height: calc(100vh - 60px);
  display: flex;
  position: relative;
  overflow: hidden;
}

.container-fluid {
  padding-top: 30px;
  height: calc(100vh - 60px);
  overflow: scroll;
}

.full-height {
  height: calc(80vh);
}

.carousel-show {
  position: relative;
  img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  &:after {
    content: '';
    padding-bottom: 40%;
    display: block;
  }
}
.custom-data-table {
  min-height: 350px;
}

.avatar {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border: 7px solid;
  border-color: $primary;
}

.btn {
  @include transition(
    opacity 0.3s ease,
    background-color 0.3s ease,
    color 0.3s ease,
    border-color 0.3s ease
  );

  &:hover,
  &:focus {
    filter: alpha(opacity = 80);
    opacity: 0.8;
  }
}

.btn-primary {
  background-color: $primary;
}

.btn-tabs {
  background-color: transparent;
  border: 2px solid $primary;
  color: $primary;
  margin-left: 5px;
  padding: 5px 30px;
  min-width: 140px !important;

  &.active-btn {
    background-color: $primary;
    color: $white;
  }
}

.btn-title {
  letter-spacing: 2px;
  line-height: 250%;
}

.btn-circle.btn-xl {
  border-radius: 15px;
  font-size: 12px;
}

.card {
  border-width: 0;
  box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  overflow: hidden;
  background: $secondary;
}

.card-body-text {
  color: $primary;
}

.datepicker-ranking {
  background-color: $primary-bg;
}

.card-img-top {
  height: 0;
  width: 100%;
  padding-bottom: calc(100% / (var(--aspect-ratio)));
}

[style^='--aspect-ratio']::before {
  content: '';
  display: inline-block;
  width: 1px;
  height: 0;
  padding-bottom: calc(100% / (var(--aspect-ratio)));
}

.card-primary-bg {
  background-color: $primary;
  box-shadow: 3.09944px 3.09944px 23.2458px rgba(0, 0, 0, 0.07);
  border-radius: 16px;
  color: $primary-bg;
}

.card-secondary-bg {
  background-color: $secondary;
  box-shadow: 3.09944px 3.09944px 23.2458px #00000012;
  border-radius: 16px;
  color: $primary !important;
}

.spacer-primary {
  margin-left: 6px;
  margin-right: 6px;
  width: 2px;
  background-color: $primary;
}

.card-title {
  font-weight: bold;
  color: $primary;
}

.nn-circle {
  display: flex;
  width: 10px;
  height: 10px;
  background-color: $tertiary;
  border-radius: 50%;
}

.nn-collapsed {
  display: none;
}

.price-detail-item {
  background-color: $tertiary !important;
  color: $white;
  display: flex !important;
  justify-content: space-between !important;
  flex-direction: column;
  text-align: center !important;
  border-width: 0 !important;
  border-right-color: $white;
  border-right-width: 1px !important;
}

.tab-container {
  display: flex;
  align-items: stretch;
}

.tab-label {
  flex: 1;
  text-align: center;
}

.list-group-item {
  background-color: transparent;
}

.testimonial-group > .row {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}
.testimonial-group > .row {
  flex: 0 0 auto;
}

.reward-text {
  font-weight: bold;
  font-size: small;
}

// SIDEBAR

.pro-sidebar-inner {
  margin-top: 30px;
  border-radius: 0 15px 15px 0;
  height: 80% !important;
  width: 100% !important;
  padding: 5% 10%;
  background-color: $tertiary !important;
}

.pro-menu-item {
  padding: 5% 5%;
  margin: 10px 0;
  color: $white;
  font-family: Averta-Bold !important;
}

.pro-menu-item.active {
  background: linear-gradient(
    240deg,
    rgba(233, 78, 27, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  color: $primary !important;
  border-radius: 10px;
  &:hover {
    .pro-item-content {
      color: $primary !important;
    }
  }
}

.pro-item-content {
  font-size: small;
  font-weight: bold;
  letter-spacing: 1px;
  &:hover {
    color: lighten($primary, 5%);
  }
}

// NAVBAR
.dropdown-item {
  white-space: inherit;
}
.dropdown-menu {
  width: 24vw;
  @media (max-width: 768px) {
    width: 75vw;
    overflow: scroll;
  }
}

.navbar {
  background-color: $tertiary !important;
  .navbar-brand {
    height: 40px;
    width: 100px;
    img {
      object-fit: contain;
      height: 280%;
      width: 150%;
      margin-top: -30px;
    }
  }
}

.dropdown-basic-toggle {
  display: inline-block;
  width: 90%;
  text-align: justify;
  font-size: 14px;
  font-weight: bold;
}

.dropdown-basic-menu {
  font-size: 14px;
}

.dropdown-basic-item.active {
  background-color: $gray-600;
}

.dropdown-basic-item {
  color: $gray-600;
}

// MARGENES
.m-t-0 {
  margin-top: 0 !important;
}
.m-t-10 {
  margin-top: 10px !important;
}
.m-t-20 {
  margin-top: 20px !important;
}
.m-t-30 {
  margin-top: 30px !important;
}
.m-t-40 {
  margin-top: 40px !important;
}
.m-t-50 {
  margin-top: 50px !important;
}

.m-b-0 {
  margin-bottom: 0 !important;
}
.m-b-10 {
  margin-bottom: 10px !important;
}
.m-b-15 {
  margin-bottom: 15px !important;
}
.m-b-20 {
  margin-bottom: 20px !important;
}
.m-b-30 {
  margin-bottom: 30px !important;
}
.m-b-40 {
  margin-bottom: 40px !important;
}
.m-b-50 {
  margin-bottom: 50px !important;
}

.font-w-b {
  font-weight: bold;
}

.badge-primary {
  color: $white;
  background-color: $badge-bg-primary;
  padding: 8px 10px;
  border-radius: 20px;
}

.badge-red {
  color: $badge-text-primary;
  background-color: $badge-bg-red;
  padding: 8px 10px;
  border-radius: 20px;
  font-size: 14px;
}

.badge-gray {
  color: $primary;
  background-color: $secondary;
  padding: 8px;
  border-radius: 20px;
  font-size: 14px;
}

.screen-title {
  font-weight: bold;
}

.time-notification {
  font-weight: 700;
  color: $gray-900 !important;
}

.notification-container-icon {
  align-self: center;
  text-align: center;
}

.profile-dropdown-link {
  display: inline-block;
  padding: 0.4em;
}

.layout-container {
  padding-inline: 30px;
  @media (max-width: 768px) {
    padding-inline: 20px;
  }
}

.container-login-form {
  @media (min-width: 960px) {
    margin-top: 3% !important;
  }
}

.login-logo {
  max-width: 500px;
}

.bg-color {
  background-color: $primary-bg;
}

.bg-color-primary {
  background-color: $primary;
}

.bg-color-secoundary {
  background-color: $secondary;
}

//datatable styles

.epTcix {
  background-color: $primary-bg;
}

.datatable {
  header {
    background-color: $primary-bg;
    input {
      background-color: $primary-bg;
    }
  }
}

.nav-logo {
  width: 70% !important;
  margin-left: 20px;
}
