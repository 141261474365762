.custom-file-img {
  position: relative;
  img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  &:after {
    content: '';
    padding-bottom: 56.25%;
    display: block;
  }
}

.custom-file-container {
  bottom: 21px;
  position: absolute;
  display: inline-block;
  width: 100px;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  display: none;
}

.icon-plus-style {
  cursor: pointer;
  svg:hover {
    color: #ccc;
  }
}
