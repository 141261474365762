.product-container {
  height: 340px;
  overflow: hidden;
  border-radius: 10px !important;
  img {
    object-fit: cover;
    width: 100%;
    height: 136px;
  }
}

.points {
  font-size: small;
}

.redeem-btn {
  text-align: center;
  padding-left: 15%;
  padding-right: 15%;
  padding-bottom: 25px;
}

.product-info-container-tab {
  border-radius: 10px !important;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.14);
  padding: 10px;
}
.tab-content {
  padding-inline: 10px;
}

.product-order-container {
  @media (max-width: 575px) {
    margin-block: 10px;
  }
}
.product-multiselect-container {
  @media (max-width: 575px) {
    margin-block: 10px;
  }
}
