.carousel {
  margin-bottom: 20px;
  position: relative;
  height: 25vh;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: '0px 0px 9px rgb(0 0 0 / 48%)';
  .carousel-inner {
    width: 100%;
    height: 100%;
    .carousel-item {
      width: 100%;
      height: 100%;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
  &:after {
    content: '';
    display: block;
  }
}
